import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

axiosInstance.interceptors.request.use(
  async config => {
    config.headers.common.Authorization = `Bearer ${getCookie("__session")}`;
    return config
  },
  (error) => Promise.reject(error)
);

function getCookie(name) {
  const exp = `(^| )${name}=([^;]+)`
  const match = document.cookie.match(new RegExp(exp));
  if (!match) {
    return '';
  }

  return match[2];
}

export default axiosInstance;
