import { useContext } from 'react';
//
import { DataContext } from '../contexts/DataContext';

// ----------------------------------------------------------------------

const useData = () => {
  const context = useContext(DataContext);

  if (!context) throw new Error('DataContext must be use inside DataProvider');

  return context;
};

export default useData;
