import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  codes: { byId: {}, allIds: [] },
  isOpenModal: false,
};

const slice = createSlice({
  name: 'qr',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CODES
    getQRCodesSuccess(state, action) {
      const codes = action.payload;

      state.isLoading = false;
      state.codes.byId = objFromArray(codes);
      state.codes.allIds = Object.keys(state.codes.byId);

      console.log(`Get Codes Success, payload: ${codes.toString()}`);
    },

    // CREATE CODE
    createQRCodeSuccess(state, action) {
      const newMail = action.payload;
      state.isLoading = false;
      state.mails = [...state.mails, newMail];
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal } = slice.actions;

// ----------------------------------------------------------------------

export function getQRCodes() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/qr/getCodes');
      dispatch(slice.actions.getQRCodesSuccess(response.data.qrcodes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createQRCode(newIssue) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/qr/createCode', newIssue);
      dispatch(slice.actions.createQRCodeSuccess(response.data.qrcodes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
