import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  changes: { all: {}, ids: [] },
  isOpenModal: false,
};

const slice = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CHANGES
    getChangesSuccess(state, action) {
      const changes = action.payload;

      state.isLoading = false;
      state.changes.all = objFromArray(action.payload);
      state.changes.ids = Object.keys(state.changes.all);
    },

    // CREATE CHANGE
    createChangeSuccess(state, action) {
      const changes = action.payload;

      state.isLoading = false;
      state.changes.all = objFromArray(action.payload);
      state.changes.ids = Object.keys(state.changes.all);
    },

    // OPEN MODAL
    openChangesModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeChangesModal(state) {
      state.isOpenModal = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openChangesModal, closeChangesModal } = slice.actions;

// ----------------------------------------------------------------------

export function getChanges(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/changelog', { params });
      dispatch(slice.actions.getChangesSuccess(response.data.changes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createChange(newChange) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/changelog/newChange', newChange);
      dispatch(slice.actions.createChangeSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
