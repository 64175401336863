// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const getSVGIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const getIconifyIcon = (name) => <Iconify icon={`${name}`} sx={{ width: 1, height: 1}} />;

const ICONS = {
  blog: getSVGIcon('ic_blog'),
  cart: getSVGIcon('ic_cart'),
  chat: getSVGIcon('ic_chat'),
  mail: getSVGIcon('ic_mail'),
  user: getIconifyIcon('ph:user-fill'),
  kanban: getSVGIcon('ic_kanban'),
  banking: getSVGIcon('ic_banking'),
  calendar: getSVGIcon('ic_calendar'),
  ecommerce: getSVGIcon('ic_ecommerce'),
  analytics: getSVGIcon('ic_analytics'),
  dashboard: getSVGIcon('ic_dashboard'),
  booking: getSVGIcon('ic_booking'),
  qr: getIconifyIcon('majesticons:qr-code'),
  forms: getIconifyIcon('tabler:forms'),
  changelog: getIconifyIcon('bi:file-earmark-code-fill'),
  settings: getIconifyIcon('ri:settings-3-fill')
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Operations',
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'issues', path: PATH_DASHBOARD.general.issues, icon: ICONS.kanban, perms: ['operations'],
        info: (
          <Label variant="ghost" color="info">
            open
          </Label>
        ),
      },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
    ],
  },
  {
    subheader: 'Administrator',
    perms: ['manageusers'],
    items: [
      { title: 'configuration', icon: ICONS.settings, children: [
        { title: 'user list', path: PATH_DASHBOARD.admin.users, },
        { title: 'store list', path: PATH_DASHBOARD.admin.stores, disabled: true },
        { title: 'issue labels', path: PATH_DASHBOARD.admin.labels, disabled: true },
      ]},
    ],
  },
  {
    subheader: 'Information',
    items: [
      { title: 'changelog', path: PATH_DASHBOARD.general.changelog, icon: ICONS.changelog },
    ],
  }
];

export default navConfig;
