import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { Container, Typography } from '@mui/material';
// hooks
import useAuth from '../hooks/useAuth';
// components
import { MotionContainer, varBounce } from '../components/animate';
// ----------------------------------------------------------------------

PermissionBasedGuard.propTypes = {
  hasContent: PropTypes.bool,
  perms: PropTypes.arrayOf(PropTypes.string), // Example ['admin', 'leader']
  children: PropTypes.node.isRequired,
};

export default function PermissionBasedGuard({ hasContent, perms, children }) {
  // Logic here to get current user role
  const { user } = useAuth();

  const userPermissions = user?.permissions;

  if (typeof perms !== 'undefined' && !perms.some( perm => userPermissions.includes(perm))) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center', pt: 3 }}>
          <Typography variant="h3" paragraph>
            Insufficient Permissions
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>You do not have permission to access this page.<br/>If you believe this to be an error, please contact Mack.</Typography>

      </Container>
    ) : null;
  }

  return <>{children}</>;
}
