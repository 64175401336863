import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: null,
  mails: { byId: {}, allIds: [] },
  labels: [],
  stores: [],
  isOpenModal: false,
  events: { byId: {}, allIds: [], now: 0 },
  current: ""
};

const slice = createSlice({
  name: 'issue',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LABELS
    getLabelsSuccess(state, action) {
      state.isLoading = false;
      state.labels = action.payload;
    },

    // GET STORES
    getStoresSuccess(state, action) {
      state.isLoading = false;
      state.stores = action.payload;
    },

    // GET MAILS
    getMailsSuccess(state, action) {
      const mails = action.payload;

      state.isLoading = false;
      state.mails.byId = objFromArray(mails);
      state.mails.allIds = Object.keys(state.mails.byId);
      state.mails.allIds.reverse();
    },

    // GET MAIL
    getMailSuccess(state, action) {
      const mail = action.payload;

      state.mails.byId[mail[0].id] = mail[0];
      if (!state.mails.allIds.includes(mail[0].id)) {
        state.mails.allIds.push(mail[0].id);
      }
    },

    // GET ISSUE EVENTS
    getIssueEventsSuccess(state, action) {
      const events = action.payload;

      state.isLoading = false;
      state.events.byId = objFromArray(events);
      state.events.allIds = Object.keys(state.events.byId);
      state.events.now = Math.round(Date.now() / 1000);
    },

    // CHANGE MESSAGE
    changeMessage(state, action) {
      const message = action.payload;
      state.message = message;
    },

    // CREATE ISSUE
    createIssueSuccess(state, action) {
      const newMail = action.payload;
      state.isLoading = false;
      state.mails = [...state.mails, newMail];
    },

    // CLOSE ISSUE
    closeIssueSuccess(state, action) {
      const event = action.payload;
      const updateIssue = state.mails.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateIssue;
    },

    // OPEN ISSUE
    openIssueSuccess(state, action) {
      const event = action.payload;
      const updateIssue = state.mails.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateIssue;
    },

    // ARCHIVE ISSUE
    archiveIssueSuccess(state, action) {
      const event = action.payload;
      const updateIssue = state.mails.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateIssue;
    },

    // COST ISSUE
    costIssueSuccess(state, action) {
      console.log(`Mail cost updated`);
      const event = action.payload[0];
      console.log(event)
      const updateIssue = state.mails.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateIssue;
    },

    // RELABEL ISSUE
    relabelIssueSuccess(state, action) {
      console.log(`Issue label updated`);
      const event = action.payload[0];
      console.log(event)
      const updateIssue = state.mails.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateIssue;
    },


    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal } = slice.actions;

// ----------------------------------------------------------------------

export function getLabels() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/issues/labels');
      dispatch(slice.actions.getLabelsSuccess(response.data.labels));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLabelsAsGuest() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/issues/guestLabels');
      dispatch(slice.actions.getLabelsSuccess(response.data.labels));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getStoresAsGuest() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/issues/guestStores');
      dispatch(slice.actions.getStoresSuccess(response.data.stores));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMails(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/issues', { params });
      dispatch(slice.actions.getMailsSuccess(response.data.issues));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMail(mailId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/issues/issue', {
        params: { mailId },
      });
      dispatch(slice.actions.getMailSuccess(response.data.issues));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getIssueEvents(mailId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/issues/issue/events', {
        params: { mailId },
      });
      dispatch(slice.actions.getIssueEventsSuccess(response.data.issueEvents));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setMessage(message) {
  return async () => {
    dispatch(slice.actions.changeMessage(message));
  };
}

// ----------------------------------------------------------------------

export function createIssue(newIssue) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/issues/createIssue', newIssue);
      dispatch(slice.actions.createIssueSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createIssueAsGuest(newIssue) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/issues/guestCreateIssue', newIssue);
      dispatch(slice.actions.createIssueSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function closeIssue(closedIssue) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/issues/closeIssue', closedIssue);
      dispatch(slice.actions.closeIssueSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function openIssue(openedIssue) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/issues/openIssue', openedIssue);
      dispatch(slice.actions.openIssueSuccess(response.data.issues));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function archiveIssue(archivedIssue) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/issues/archiveIssue', archivedIssue);
      dispatch(slice.actions.archiveIssueSuccess(response.data.issues));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function costIssue(costedIssue) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/issues/costIssue', costedIssue);
      dispatch(slice.actions.costIssueSuccess(response.data.issues));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function relabelIssue(relabeledIssue) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/issues/relabelIssue', relabeledIssue);
      dispatch(slice.actions.relabelIssueSuccess(response.data.issues));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
