// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    changelog: path(ROOTS_DASHBOARD, '/changelog'),
    issues: path(ROOTS_DASHBOARD, '/issues'),
  },
  issues: {
    root: path(ROOTS_DASHBOARD, '/issues'),
    all: path(ROOTS_DASHBOARD, '/issues/all'),
    report: path(ROOTS_DASHBOARD, '/report')
  },
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
    config: path(ROOTS_DASHBOARD, '/admin/config'),
    users: path(ROOTS_DASHBOARD, '/admin/users'),
    stores: path(ROOTS_DASHBOARD, '/admin/stores'),
    labels: path(ROOTS_DASHBOARD, '/admin/labels'),
    editUser: (uid) => path(ROOTS_DASHBOARD, `/admin/users/${uid}`),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
