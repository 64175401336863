import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import { getApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword
} from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, setDoc, query, where, getDocs, onSnapshot, orderBy, limit, startAfter } from 'firebase/firestore';
//
import { FIREBASE_API } from '../config';

// ----------------------------------------------------------------------

const firebaseApp = getApp();

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

const initialState = {
  isInitialized: false,
};

const reducer = (state, action) => {
  if (action.type === 'INITIALIZE') {
    const { meta, issues } = action.payload;
    return {
      ...state,
      isInitialized: true,
      meta,
      issues,
    };
  }
  return state;
};

const DataContext = createContext({
  ...initialState
});

// ----------------------------------------------------------------------

DataProvider.propTypes = {
  children: PropTypes.node,
};

function DataProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [meta, setMeta] = useState(null);
  const [labels, setLabels] = useState({});
  const [issues, setIssues] = useState({});
  const [users, setUsers] = useState([]);
  const [issuesStartAfter, setIssuesStartAfter] = useState(0);

  const getIssues = async (label, after) => {
    const first = query(collection(DB, "issues"), orderBy("createdAt", "desc"), limit(2));
    const documentSnapshots = await getDocs(first);
    // Get the last visible document
    const lastVisible = issues && documentSnapshots.docs[documentSnapshots.docs.length - 1];
    const snapshotIssues = {};
    documentSnapshots.forEach((doc) => {
      snapshotIssues[doc.id] = (doc.data());
    });

    setIssuesStartAfter(lastVisible?.data().createdAt._seconds);
    setIssues(snapshotIssues);
  };

  const getUsers = async () => {
    const usersQuery = query(collection(DB, "users"), orderBy("createdOn", "asc"));
    const documentSnapshots = await getDocs(usersQuery);
    // Get the last visible document
    // const lastVisible = issues && documentSnapshots.docs[documentSnapshots.docs.length - 1];
    const snapshotUsers = [];
    documentSnapshots.forEach((doc) => {
      snapshotUsers.push(doc.data());
    });

    // setIssuesStartAfter(lastVisible?.data().createdAt._seconds);
    setUsers(snapshotUsers);
  };

  const getUser = async (uid) => {
    const userRef = doc(DB, "users", uid);
    const docSnap = await getDoc(userRef);

    if (docSnap.exists()) {
      const snapshotUsers = [];
      snapshotUsers.push(docSnap.data());
      setUsers(snapshotUsers);
    } else {
      console.log("No such document!");
    }

    return docSnap.data();
  };



  useEffect(
    async () =>
      {
        if(!state.isInitialized) {

          // META
          const metaRef = doc(DB, 'meta', 'site');
          const metaSnap = await getDoc(metaRef);

          if (metaSnap.exists()) {
            setMeta(metaSnap.data());
          }

          getIssues("repairs", Date.now());

          // LABELS
          const labelsQuery = query(collection(DB, 'issue-labels'));
          const unsub = onSnapshot(labelsQuery, (querySnapshot) => {
            const snapshotLabels = {};
            querySnapshot.forEach((doc) => {
                snapshotLabels[doc.id] = (doc.data());
            });
            setLabels(snapshotLabels);
          });

          // INITIALIZE
          dispatch({
            type: 'INITIALIZE',
            payload: { isInitialized: true },
          });
        }
      },
    [dispatch]
  );

  return (
    <DataContext.Provider
      value={{
        ...state,
        issues,
        meta: {...meta, labels},
        users,
        getIssues,
        getUsers,
        getUser,
        issuesStartAfter,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export { DataContext, DataProvider };
